import { useTranslation } from "react-i18next";
import { Select, SelectContent, SelectItem, SelectTrigger } from "./ui/select";
import Globe from "../assets/images/globe.svg?react";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleChange = (value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <Select defaultValue={i18n.language} onValueChange={handleChange}>
      <SelectTrigger className="flex items-center justify-center w-[48px] p-0">
        <Globe />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="en-US">🇺🇸 English</SelectItem>
        <SelectItem value="ja-JP">🇯🇵 日本語</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default LanguageSwitcher;
