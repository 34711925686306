import { Tag } from "../utils/types";

interface WalletItemProps {
  tag: Tag;
}

const WalletItem: React.FC<WalletItemProps> = ({ tag }) => {
  return (
    <div>
      <img
        src={tag.image_url}
        alt={tag.name}
        className="w-full h-auto mb-4 rounded-[10px]"
      />
      <h3 className="font-abcFavorit text-2xl font-semibold mb-1 truncate">
        {tag.name}
      </h3>
      <p className="font-abcFavorit text-sm text-[#202020] mb-4 line-clamp-2">
        {tag.description}
      </p>
    </div>
  );
};

export default WalletItem;
