import { useTranslation } from "react-i18next";
import EmptyStateImage from "../assets/images/empty-state.webp";

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-center">
        <img
          src={EmptyStateImage}
          alt="Empty Collection"
          style={{ maxHeight: "700px" }}
        />
      </div>
      <p className="font-abcFavorit text-[#202020]">
        {t(
          "empty-wallet.description",
          "There are no items in Your Collection. Verify a Legitimate Tag to add to Your Collection.",
        )}
      </p>
    </div>
  );
};

export default EmptyState;
