import './config/instrument';

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";

import Root from "./routes/root";
import Wallet from "./routes/wallet";

import { collectionLoader } from "./data/loaders";
import { ThemeProvider } from "./contexts/ThemeProvider";

import client from "./config/client";
import "./i18n";

import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/:walletAddressOrUsername",
    loader: collectionLoader,
    element: <Wallet />,
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={client}>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  </StrictMode>,
);
