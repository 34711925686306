import Header from "@/components/Header";
import LanguageSwitcher from "@/components/LanguageSelect";
import { useTranslation } from "react-i18next";
import CollectionHomepageImage from "../assets/images/collection-homepage.webp";
import EmptyState from "../assets/images/empty-state.webp";

const Root = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-screen-xl mx-auto px-4 py-4">
      <Header right={<LanguageSwitcher />} />

      <h1 className="text-5xl font-bold mb-4 font-psTimes">
        {t("home.title.your-collection", "Your Collection")}
      </h1>

      <p className="font-abcFavorit text-md text-[#202020] mb-4">
        {t(
          "home.title.description",
          "Share it with the world. Or keep it to yourself.",
        )}
      </p>

      <div className="w-full hidden md:block px-4">
        <img
          src={CollectionHomepageImage}
          alt="Your Collection"
          className="w-full"
        />
      </div>

      <div className="flex w-full md:hidden mt-24 justify-center">
        <img
          src={EmptyState}
          alt="Your Collection"
          className="w-4/5"
        />
      </div>
    </div>
  );
};

export default Root;
