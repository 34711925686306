import Symbol from "../assets/images/symbol.svg?react";

interface HeaderProps {
  right?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ right }) => {
  return (
    <div className="flex justify-between items-center mt-1 mb-6">
      <Symbol height={32} style={{ color: "#000000" }} />
      {right}
    </div>
  );
};

export default Header;
