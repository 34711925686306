import React from "react";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tag } from "../utils/types";
import WalletItem from "../components/WalletItem";
import Header from "@/components/Header";
import OwnedBy from "@/components/OwnedBy";
import { DynamicUserProfile } from "@/types/dynamic";
import LanguageSwitcher from "@/components/LanguageSelect";
import EmptyState from "@/components/EmptyState";

const Wallet = () => {
  const { t } = useTranslation();
  const data = useLoaderData() as { user: DynamicUserProfile; tags: Tag[] };
  const revalidator = useRevalidator();

  React.useEffect(() => {
    const listener = () => {
      revalidator.revalidate();
    };

    window.addEventListener("focus", listener);

    return () => {
      window.removeEventListener("focus", listener);
    };
  }, [revalidator]);

  return (
    <div className="w-full max-w-screen-xl mx-auto px-4 py-4">
      <Header
        right={
          <div className="flex gap-2 items-center">
            <LanguageSwitcher />
            <div className="hidden md:block">
              <OwnedBy user={data.user} />
            </div>
          </div>
        }
      />

      <h1 className="text-5xl font-bold my-4 font-psTimes">
        {t("wallet.title.your-collection", "Your Collection")}
      </h1>

      <p className="font-abcFavorit text-md text-[#202020] mb-4">
        {t(
          "wallet.title.description",
          "Share it with the world. Or keep it to yourself.",
        )}
      </p>

      <div className="md:hidden mb-4">
        <OwnedBy user={data.user} />
      </div>

      {data.tags.length === 0 ? (
        <EmptyState />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {data.tags.map((tag, id) => (
            // Use `tag.uid` here once we migrate `tag.uuid` to `tag.uid`
            <WalletItem key={id} tag={tag} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Wallet;
